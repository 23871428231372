const theme = {
  meta: {
    fbDomainVerification: null,
  },
  marketo: {
    url: '//app-lon10.marketo.com',
    munchkinId: '897-MBC-207',
  },
  fullWidthIframe: false,
  scrollOffset: 100,
  sitecore: {
    SSO: {
      prod: 'www.canneslions.com', // prod env
      staging: 'cannes-uat.lionsfestivals.com', // uses YAP, it is the pre-prod env
      test: 'cannes-uat.lionsfestivals.com', //
      integration: 'cannes-uat.lionsfestivals.com', // Integration env, used by DMS team
    },
    urlPath: 'lions-live',
    cookieLifetime: 14, // in days
    logoutPath: '',
  },
  header: {
    position: 'sticky',
    place: null,
    variation: null,
  },
  annoucementVariation: {
    type: 'annoucement',
  },
  footer: {
    type: 'primary', // default is primary
  },
  eventDate: '',
  events: {
    pathSingular: 'event',
  },
  featuredVideo: {
    overlay: {
      title: 'Create a free account',
      subtitle: `or log in with your existing Cannes Lions account details`,
      buttonVariant: 'secondary',
    },
  },
  speakers: {
    labelSingular: 'Speaker',
    labelPlural: 'Speaker',
    pageDescription: '',
    pageTitle: 'Speakers',
    pathSingular: 'speaker',
    pathPlural: 'speakers',
    queryParams: {},
    shareButtons: false,
  },
  videoPlatform: {
    livePlayer: 'iframe', // One of two options, brightcove or iframe
    hasPayWall: false,
    carouselsUrl: null,
    eventsUrl: null,
    speakersUrl: null,
    featuredVideoOverlay: {
      title: `<h1>LIVE <br />
            STREAMING <br />
            NOW</h1>`,
      titleAuth: `<h1>LIVE <br />
            STREAMING <br />
            NOW</h1>`,
      button: {
        link: '/',
        text: 'Watch Now',
        textAuth: 'Watch now',
        variant: 'primary',
      },
      overlayMarginTop: '7.2rem',
    },
    feedbackForm: {
      showForm: false,
      prod: '',
      staging: '',
    },
    liveTitleUrl: null,
    multiBrand: false,
    pagePrefix: null,
    timezoneIndicator: 'BST',
    videoOverlay: {
      title: 'Create a free account',
      subtitle: 'or log in with your existing Cannes Lions account details',
      buttonText: 'Login/Register',
    },
  },
  name: 'Horizon',
  notFound: {
    cta: {
      label: 'Watch Now',
      url: '/',
    },
    message: `But back at Ascential, you’ll find hours of original thinking and provocative views on what’s next from the most creative companies in the world.`,
    title: 'Nothing to see here.',
  },
  copyrightText: 'Copyright Ascential Events (Europe) Limited',
  carouselTextLimit: 130,
  showBreadcrumbs: true,
  showInPartnershipText: true,
  taxonomy: [
    {
      id: 'category',
      nameSingular: 'Category',
      namePlural: 'Categories',
      slugSingular: 'category',
      slugPlural: 'categories',
    },
  ],
  digest: {
    isEnabled: false,
    pageSize: 12,
  },
}

export default theme
