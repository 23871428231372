module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Money20/20","short_name":"Money20/20","background_color":"#282828","theme_color":"#454545","icon":"src/assets/images/M2020.png","start_url":"/","display":"minimal-ui","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b79a237271d7265bab9b6e053f607db1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NLPLW2D","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/404/","/404.html"],"crumbLabelUpdates":[{"pathname":"/what-ive-learnt","crumbLabel":"What I've Learnt..."},{"pathname":"/moneyfest","crumbLabel":"MoneyFest"},{"pathname":"/moneypot","crumbLabel":"MoneyPot"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
