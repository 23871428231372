import money2020eu from './money2020eu'
import money2020us from './money2020us'

const theme = {
  children: {
    money2020eu,
    money2020us,
  },
  featuredVideo: {
    overlay: {
      title: `Catch up on money's biggest conversation`,
      subtitle: 'Available on demand',
    },
  },
  scrollOffset: 10,
  sitecore: {
    SSO: {
      prod: 'account.money2020.com',
      staging: 'staging-account.money2020.com',
      test: 'uat-account.money2020.com',
      integration: 'uat-account.money2020.com',
    },
    urlPath: 'moneyfest',
    cookieLifetime: 14, // in days
    logoutPath: 'moneyfest/',
  },
  videoPlatform: {
    carouselsUrl: {
      production: '',
      staging: '',
      test:
        'https://portal.ascentialevents.com/api/UATCarousel/getCarousels?key=mf-9wfMINcpHJ7Za&festival_year=2020',
      integration:
        'https://portal.ascentialevents.com/api/UATCarousel/getCarousels?key=mf-9wfMINcpHJ7Za&festival_year=2020',
    },
    eventsUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/multi/moneyfest',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/multi/moneyfest',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/multi/moneyfest/uat',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/events/multi/moneyfest/uat',
    },
    speakersUrl: {
      production:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/mf2021/3',
      staging:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/mf2021/3',
      test:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/mf2021/3/uat',
      integration:
        'https://0jaku7mk0a.execute-api.eu-west-1.amazonaws.com/api/speakers/mf2021/3/uat',
    },
    featuredVideoOverlay: {
      title: `<h2>Money's biggest conversation has arrived</h2><h4>Money's greatest minds are coming to your screens. Available free and on demand.</h4>`,
      button: {
        text: 'Login / Register',
      },
    },
    feedbackForm: {
      showForm: true,
      prod:
        'https://portal-api.ascentialevents.com/api/ContentUserRating/rating',
      staging:
        'https://dev-rating-cl-content.ae-sandbox.net/api/ContentUserRating/rating',
      integration:
        'https://dev-rating-cl-content.ae-sandbox.net/api/ContentUserRating/rating',
    },
    liveTitleUrl: null,
    timezoneIndicator: 'EDT',
    pagePrefix: { slug: 'moneyfest' },
    videoOverlay: {
      title: 'Create a Free Account',
      subtitle: `Or log in with your existing MoneyFest details`,
      buttonText: 'Login / Register',
    },
  },
  header: {
    position: 'static',
    loginRequired: false,
  },
  speakers: {
    pageTitle: 'Speakers | MoneyFest 2021',
    pageDescription: `See the line-up for MoneyFest 2021 and learn more about each speaker and the sessions they'll lead.`,
    pathSingular: 'speaker',
    pathPlural: 'speakers',
    queryParams: {},
    shareButtons: true,
  },
  name: 'Money20/20',
  notFound: {
    cta: {
      label: 'Watch Now',
      url: '/moneyfest/watch-now',
    },
    message: `But back at Money20/20, you'll find heaps of original thinking, provocative views and critical insights on what's next for the future of money`,
    title: 'Nothing to see here.',
  },
}

export default theme
